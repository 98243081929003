import { IMY24CTA } from '@/components/sections/my24/shared/cta';
import {
  MENU_LCV_ABOUT_CONTACT,
  MENU_LCV_CALCULATOR,
  MENU_LCV_REGISTER,
} from '@/contents/lcv/link';

export const getCta = (
  page:
    | 'home'
    | 'v-cross'
    | 'x-series'
    | 'mu-x'
    | 'pickup-2-door'
    | 'pickup-4-door'
    | 'spark'
) => {
  switch (page) {
    // case 'x-series':
    //   // return getProductCtaPup('?tag=x-series');
    // case 'v-cross':
    //   // return getProductCtaPup('?tag=v-cross');
    //   return getProductCtaPup(
    //     'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt6086e8b8b14bd693/650a549aff69780bd71c4122/v-cross.jpg',
    //     'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt697f8d43d3fc83db/650a54b7b556bd7e2fd3b6d4/v-cross.jpg',
    //     '/v-cross-4-door-financial'
    //   );
    // case 'pickup-4-door':
    //   // return getProductCtaPup('?tag=hilander&tag=cab4');
    //   return getProductCtaPup(
    //     'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt50396d0288863d76/650a5403c5537cf12c4d034c/4-door.jpg',
    //     'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt07b9a569da35dd53/650a52cab29ddbdf60544d62/4-door.jpg',
    //     '/hilander-4-doors-financial'
    //   );
    // case 'pickup-2-door':
    //   // return getProductCtaPup('?tag=spacecab');
    //   return getProductCtaPup(
    //     'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltfc80906da208b0d6/651fb86662df4415576dc3c6/2-door.jpg',
    //     'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blta0a5cee339d2ba81/651fb891705ef39e5348b5bd/2-door.jpg',
    //     '/spacecab-s-financial'
    //   );
    // case 'spark':
    //   // return getProductCtaPup('?tag=spark');
    //   return getProductCtaPup(
    //     'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltd7dadc5cd4a0b21b/650a544b7e03024b28bbe95b/spark.jpg',
    //     'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt7d9d47398075cb40/650a53bef0b16bdb0e1d9253/spark.jpg',
    //     '/spark-1-9-financial'
    //   );
    // case 'mu-x':
    //   return getProductCtaPpv();
    default:
      return getCtaDefault();
  }
};

const getCtaDefault = (): IMY24CTA => {
  return {
    active: true,
    headline: {
      title: 'CTA',
    },
    items: [
      {
        preTitle: 'Register',
        title: 'ສົນໃຈລົດ / <span><br /></span>ທົດລອງຂັບ',
        description: `ລົງທະບຽນສົນໃຈລົດອີຊູຊຸ<span>ຫຼືທົດລອງຂັບบ</span>`,
        image: {
          src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt004c93d1f84c1a79/657c04c5ab849956e41f9159/laos-cta-register-m.jpg',
          alt: 'Register',
        },
        imageDesktop: {
          src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltecbc17c26d7dd73c/657c04c555e71b3501cddfef/laos-cta-register.jpg',
          alt: 'Register',
        },
        buttons: [
          {
            label: 'ເບິ່ງລາຍລະອຽດ',
            url: MENU_LCV_REGISTER.url,
            target: MENU_LCV_REGISTER.target,
            icon: 'arrow-forward-2',
            minWidth: true,
            color: 'white',
          },
        ],
      },
      {
        preTitle: 'Calculator',
        title: 'ຄໍານວນຄ່າງວດ',
        description: `ລອງຄິດໄລ່ຄ່າງວດທີ່ຕ້ອງຈ່າຍ`,
        image: {
          src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt301980b22144732e/657c04c4b6bd4ab85f396e8a/laos-cta-cal-m.jpg',
          alt: 'Calculator',
        },
        imageDesktop: {
          src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt36ac3d6560a3d52d/657c04c44a95e56a0adc9982/laos-cta-cal.jpg',
          alt: 'Calculator',
        },
        buttons: [
          {
            label: 'ເບິ່ງລາຍລະອຽດ',
            url: MENU_LCV_CALCULATOR.url,
            target: MENU_LCV_CALCULATOR.target,
            icon: 'arrow-forward-2',
            minWidth: true,
            color: 'white',
          },
        ],
      },
      {
        preTitle: 'Contact Us',
        title: 'ຕິດຕໍ່ເຮົາ',
        description: 'ຊອກຫາຕົວແທນອີຊູຊຸໃກ້ທ່ານ',
        image: {
          src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blta013664c15e12cd8/657c04c50f816d37bf4d4418/laos-cta-contact-m.jpg',
          alt: 'Contact Us',
        },
        imageDesktop: {
          src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt20870fcc642ab34a/657c04c47537fe98ef23cd2d/laos-cta-contact.jpg',
          alt: 'Contact Us',
        },
        buttons: [
          {
            label: 'ເບິ່ງລາຍລະອຽດ',
            url: MENU_LCV_ABOUT_CONTACT.url,
            target: MENU_LCV_ABOUT_CONTACT.target,
            icon: 'arrow-forward-2',
            minWidth: true,
            color: 'white',
          },
        ],
      },
    ],
    dataTrack: 'lcv-home',
  };
};

// const getProductCtaPpv = (): IMY24CTA => {
//   return {
//     active: true,
//     headline: {
//       title: 'CTA',
//     },
//     items: [
//       {
//         preTitle: 'Special Offer',
//         title: 'ข้อเสนอพิเศษ',
//         description: 'โปรโมชันล่าสุดสำหรับรถปิกอัพและรถอเนกประสงค์',
//         image: {
//           src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt43296becac03c612/630c627e847c967c7c96baa1/cta-special_offer.jpg',
//           alt: 'ISUZU Special Offer',
//         },
//         imageDesktop: {
//           src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt43296becac03c612/630c627e847c967c7c96baa1/cta-special_offer.jpg',
//           alt: 'ISUZU Special Offer',
//         },
//         buttons: [
//           {
//             label: 'ເບິ່ງລາຍລະອຽດ',
//             url: MENU_LCV_SPECIAL_OFFER.url,
//             target: MENU_LCV_SPECIAL_OFFER.target,
//             icon: 'arrow-forward-2',
//             minWidth: true,
//             color: 'white',
//           },
//         ],
//       },
//       {
//         preTitle: 'Gallery',
//         title: 'แกลเลอรี่',
//         description: 'รวมรูปภาพสวยๆ อย่างมีสไตล์ของรถอีซูซุ',
//         image: {
//           src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blte6b3ea906d8c1c6f/63db57abbbcc27228d8e0a47/MU-X_CTA.png',
//           alt: 'MU-X Gallery',
//         },
//         imageDesktop: {
//           src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blte6b3ea906d8c1c6f/63db57abbbcc27228d8e0a47/MU-X_CTA.png',
//           alt: 'MU-X Gallery',
//         },
//         buttons: [
//           {
//             label: 'ເບິ່ງລາຍລະອຽດ',
//             url: `${MENU_LCV_CONTENT_HUB_GALLERY.url}?tag=mu-x`,
//             target: MENU_LCV_CONTENT_HUB_GALLERY.target,
//             icon: 'arrow-forward-2',
//             minWidth: true,
//             color: 'white',
//           },
//         ],
//       },
//       {
//         preTitle: 'MU-X the Exclusive',
//         title: 'เอกสิทธิ์สุดพิเศษ',
//         description: 'สำหรับเจ้าของรถอีซูซุมิว-เอ็กซ์',
//         image: {
//           src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt315d0a9cc79000ac/630c62561ee78a063c597053/cta-mux-exclusive.jpg',
//           alt: 'MU-X the Exclusive',
//         },
//         imageDesktop: {
//           src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt315d0a9cc79000ac/630c62561ee78a063c597053/cta-mux-exclusive.jpg',
//           alt: 'MU-X the Exclusive',
//         },
//         buttons: [
//           {
//             label: 'ເບິ່ງລາຍລະອຽດ',
//             url: MENU_LCV_PRIVILEGES_MUX_THE_EXCLUSIVE.url,
//             target: MENU_LCV_PRIVILEGES_MUX_THE_EXCLUSIVE.target,
//             icon: 'arrow-forward-2',
//             minWidth: true,
//             color: 'white',
//           },
//         ],
//       },
//     ],
//     dataTrack: 'lcv-product',
//     theme: 'dark',
//   };
// };

// // NOTE PICKUP
// const getProductCtaPup = (
//   image?: string,
//   imageDesktop?: string,
//   modelUrl?: string
// ): IMY24CTA => {
//   return {
//     active: true,
//     headline: {
//       title: 'CTA',
//     },
//     items: [
//       {
//         preTitle: 'Special Offer',
//         title: 'ข้อเสนอพิเศษ',
//         description: 'โปรโมชันล่าสุดสำหรับรถปิกอัพและรถอเนกประสงค์',
//         image: {
//           src: image,
//           alt: 'Special Offer',
//         },
//         imageDesktop: {
//           src: imageDesktop,
//           alt: 'Special Offer',
//         },
//         buttons: [
//           {
//             label: 'ເບິ່ງລາຍລະອຽດ',
//             url: MENU_LCV_SPECIAL_OFFER_PUP.url + modelUrl,
//             target: MENU_LCV_REGISTER.target,
//             icon: 'arrow-forward-2',
//             minWidth: true,
//             color: 'white',
//           },
//         ],
//       },
//       {
//         preTitle: 'Service Center',
//         title: 'ศูนย์บริการอีซูซุ',
//         description:
//           'อุ่นใจด้วยมาตรฐานการดูแลของอีซูซุ <span>ปลอดภัยทั้งรถและคุณ</span>',
//         image: {
//           src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltfb6c14fc1852acdd/650a543aa53c9df3d8450af2/showroom.jpg',
//           alt: 'Service Center',
//         },
//         imageDesktop: {
//           src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt64f07376537356c8/650a53aaa39cd6c556acf339/showroom.jpg',
//           alt: 'Service Center',
//         },
//         buttons: [
//           {
//             label: 'ເບິ່ງລາຍລະອຽດ',
//             url: MENU_LCV_SERVICE_CENTER.url,
//             target: MENU_LCV_SERVICE_CENTER.target,
//             icon: 'arrow-forward-2',
//             minWidth: true,
//             color: 'white',
//           },
//         ],
//       },
//       {
//         preTitle: 'TRI PETCH Genuine Parts',
//         title: 'อะไหล่แท้ตรีเพชร',
//         description: 'คุณภาพที่คุณมั่นใจ',
//         image: {
//           src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt9c317909e8fb9119/6502f187e60bfc806ad3f1d1/parts.jpg',
//           alt: 'TRI PETCH Genuine Parts',
//         },
//         imageDesktop: {
//           src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltd7f051c50e62f6a4/6502f17a0433c00338254862/parts.jpg',
//           alt: 'TRI PETCH Genuine Parts',
//         },
//         buttons: [
//           {
//             label: 'ເບິ່ງລາຍລະອຽດ',
//             url: MENU_LCV_SERVICE_PARTS.url,
//             target: MENU_LCV_SERVICE_PARTS.target,
//             icon: 'arrow-forward-2',
//             minWidth: true,
//             color: 'white',
//           },
//         ],
//       },
//       // {
//       //   preTitle: 'Gallery',
//       //   title: 'แกลเลอรี่',
//       //   description: 'รวมรูปภาพสวยๆ อย่างมีสไตล์ของรถอีซูซุ',
//       //   image: {
//       //     src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt4dacdcddc435c488/633aadde2b80434a8b695f3c/cta-d-max.jpeg',
//       //     alt: 'ISUZU Gallery',
//       //   },
//       //   imageDesktop: {
//       //     src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt4dacdcddc435c488/633aadde2b80434a8b695f3c/cta-d-max.jpeg',
//       //     alt: 'ISUZU Gallery',
//       //   },
//       //   buttons: [
//       //     {
//       //       label: 'ເບິ່ງລາຍລະອຽດ',
//       //       url: `${MENU_LCV_CONTENT_HUB_GALLERY.url}${tag}`,
//       //       target: MENU_LCV_CONTENT_HUB_GALLERY.target,
//       //       icon: 'arrow-forward-2',
//       //       minWidth: true,
//       //       color: 'white',
//       //     },
//       //   ],
//       // },
//       // {
//       //   preTitle: 'my-ISUZU',
//       //   title: 'มาย-อีซูซุ',
//       //   description:
//       //     'แอปพลิเคชันสำหรับเจ้าของรถอีซูซุที่จะทำให้คุณไม่พลาดกับทุกสิทธิประโยชน์',
//       //   image: {
//       //     src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt70568b0e04bd163f/631af4759dbe2b58ee19fd43/cta-myi-product.jpg',
//       //     alt: 'my-ISUZU',
//       //   },
//       //   imageDesktop: {
//       //     src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt70568b0e04bd163f/631af4759dbe2b58ee19fd43/cta-myi-product.jpg',
//       //     alt: 'my-ISUZU',
//       //   },
//       //   buttons: [
//       //     {
//       //       label: 'ເບິ່ງລາຍລະອຽດ',
//       //       url: MENU_LCV_PRIVILEGES_MY_ISUZU.url,
//       //       target: MENU_LCV_PRIVILEGES_MY_ISUZU.target,
//       //       icon: 'arrow-forward-2',
//       //       minWidth: true,
//       //       color: 'white',
//       //     },
//       //   ],
//       // },
//     ],
//     dataTrack: 'lcv-product',
//     theme: 'dark',
//   };
// };
