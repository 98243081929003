import {
  MUX_DATA,
  PICKUP_4DOOR_DATA,
  SPARK_DATA,
  V_CROSS_DATA,
} from '@/contents/lcv/product/cafe/lineup';
import { IMy24LineupItem } from '@/types/shared';

export const HOME_LINEUP_DATA: IMy24LineupItem[] = [
  {
    id: 1,
    preTitle: 'V-CROSS',
    title: V_CROSS_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltf735e79288caaf22/657faccd2d26126fa1e71ef3/laos-lineup-v-cross.png',
      alt: V_CROSS_DATA.label,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blte456dec813c4503a/651668c0098aaa9f88ce49be/home-lineup-bg-v-cross-mobile.jpg',
      alt: V_CROSS_DATA.label,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt391cae8117816279/651668f5474d4d73914f465d/home-lineup-bg-v-cross.jpg',
      alt: V_CROSS_DATA.label,
    },
    button: {
      label: 'ເບິ່ງລາຍລະອຽດ',
      icon: 'arrow-forward',
      url: V_CROSS_DATA.url,
      minWidth: true,
    },
    price: V_CROSS_DATA.price,
  },
  {
    id: 2,
    preTitle: '4-DOOR',
    title: PICKUP_4DOOR_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt1f489d054683d877/657faccd33550e5c46d59421/laos-lineup-4-door.png',
      alt: PICKUP_4DOOR_DATA.label,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt9f99dc2ea4f1adc2/65166a2623b7e78f5e6aa95c/home-lineup-bg-4-door-mobile.jpg',
      alt: PICKUP_4DOOR_DATA.label,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blteee13e08dc626ff6/651669f1ec958dbcd8ea56b4/home-lineup-bg-4-door.jpg',
      alt: PICKUP_4DOOR_DATA.label,
    },
    button: {
      label: 'ເບິ່ງລາຍລະອຽດ',
      icon: 'arrow-forward',
      url: PICKUP_4DOOR_DATA.url,
      minWidth: true,
    },
    price: PICKUP_4DOOR_DATA.price,
  },
  {
    id: 4,
    preTitle: 'SPARK',
    title: SPARK_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt45aa115a8d057931/657faccd2d26124d66e71eef/laos-lineup-spark.png',
      alt: SPARK_DATA.label,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltc51540266564afd1/651669a623b7e7b9f46aa95a/home-lineup-bg-spark-mobile.jpg',
      alt: SPARK_DATA.label,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt180e8b8b245e62df/651669cccf50bf3f2afffa5c/home-lineup-bg-spark.jpg',
      alt: SPARK_DATA.label,
    },
    button: {
      label: 'ເບິ່ງລາຍລະອຽດ',
      icon: 'arrow-forward',
      url: SPARK_DATA.url,
      minWidth: true,
    },
    price: SPARK_DATA.price,
  },
  {
    id: 5,
    preTitle: 'MU-X',
    title: MUX_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltc0ed199b5cf83a42/657faccddc76629fc07e7e75/laos-lineup-mu-x.png',
      alt: MUX_DATA.label,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt3f7a4476cab98baa/65166abeb16d6deb10554c64/home-lineup-bg-mu-x-mobile.jpg',
      alt: MUX_DATA.label,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt6c92f43d2807b858/65166a4e474d4db0ed4f4666/home-lineup-bg-mu-x.jpg',
      alt: MUX_DATA.label,
    },
    button: {
      label: 'ເບິ່ງລາຍລະອຽດ',
      icon: 'arrow-forward',
      url: MUX_DATA.url,
      minWidth: true,
    },
    price: MUX_DATA.price,
  },
];

export const PRODUCT_LINEUP_DATA: IMy24LineupItem[] = [
  {
    id: 1,
    preTitle: 'V-CROSS',
    title: V_CROSS_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltf735e79288caaf22/657faccd2d26126fa1e71ef3/laos-lineup-v-cross.png',
      alt: V_CROSS_DATA.label,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltd8659609c86edf96/657be1aa7cafa37252de80fa/laos-bg-v-cross.jpg',
      alt: V_CROSS_DATA.label,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltd8659609c86edf96/657be1aa7cafa37252de80fa/laos-bg-v-cross.jpg',
      alt: V_CROSS_DATA.label,
    },
    button: {
      label: 'ເບິ່ງລາຍລະອຽດ',
      icon: 'arrow-forward',
      url: V_CROSS_DATA.url,
      minWidth: true,
    },
    price: V_CROSS_DATA.price,
  },
  {
    id: 2,
    preTitle: '4-DOOR',
    title: PICKUP_4DOOR_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt1f489d054683d877/657faccd33550e5c46d59421/laos-lineup-4-door.png',
      alt: PICKUP_4DOOR_DATA.label,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt21e38593309a9a59/657be1aa1261c9caa5235993/laos-bg-4-door.jpg',
      alt: PICKUP_4DOOR_DATA.label,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt21e38593309a9a59/657be1aa1261c9caa5235993/laos-bg-4-door.jpg',
      alt: PICKUP_4DOOR_DATA.label,
    },
    button: {
      label: 'ເບິ່ງລາຍລະອຽດ',
      icon: 'arrow-forward',
      url: PICKUP_4DOOR_DATA.url,
      minWidth: true,
    },
    price: PICKUP_4DOOR_DATA.price,
  },
  {
    id: 4,
    preTitle: 'SPARK',
    title: SPARK_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt45aa115a8d057931/657faccd2d26124d66e71eef/laos-lineup-spark.png',
      alt: SPARK_DATA.label,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltaf0f584d4cbc998f/657be1a9268d4b1a45f9d5a5/laos-bg-spark.jpg',
      alt: SPARK_DATA.label,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltaf0f584d4cbc998f/657be1a9268d4b1a45f9d5a5/laos-bg-spark.jpg',
      alt: SPARK_DATA.label,
    },
    button: {
      label: 'ເບິ່ງລາຍລະອຽດ',
      icon: 'arrow-forward',
      url: SPARK_DATA.url,
      minWidth: true,
    },
    price: SPARK_DATA.price,
  },
  {
    id: 6,
    preTitle: 'MU-X',
    title: MUX_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltc0ed199b5cf83a42/657faccddc76629fc07e7e75/laos-lineup-mu-x.png',
      alt: MUX_DATA.label,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltf7410c7cca8b20c2/657be1aa7537fef85023cccf/laos-bg-mu-x.jpg',
      alt: MUX_DATA.label,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltf7410c7cca8b20c2/657be1aa7537fef85023cccf/laos-bg-mu-x.jpg',
      alt: MUX_DATA.label,
    },
    button: {
      label: 'ເບິ່ງລາຍລະອຽດ',
      icon: 'arrow-forward',
      url: MUX_DATA.url,
      minWidth: true,
    },
    price: MUX_DATA.price,
  },
];
